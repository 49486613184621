@import '../../../common/styles/variables';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.loader {
  width: 8rem;
  height: 8rem;
  flex-grow: 0;
}

.text {
  font-size: $font-size-large;
  font-weight: 500;
}

.figure {
    width: 6.5rem;
    height: 6.5rem;
    border-radius: 50%;
    display: block;
    padding: 20px;
    margin: 0 auto 10px;

  svg {
    width: 100%;
    height: 100%;
    fill: $color-light-white;
    color: $color-light-white;
    vertical-align: middle;
  }

  &.success {
    background-color: $color-success;
  }
  &.error {
    background-color: $color-error;
  }
}