* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: unset;
}

button, select, input[type=password], input[type=text] {
  border: none;
  outline: none;
}

