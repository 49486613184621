@import '../../common/styles/variables';
@import '../../common/styles/mixins';

.group_view  {
  display: grid;
  grid-template-rows: 3rem 1fr;

  min-height: 0;
  max-height: 100%;

  align-content: stretch;
  justify-content: stretch;
  align-items: stretch;

  .view_pt {
    grid-row: 1 / 2;
    border-bottom: 3px solid $color-light-border;
  }

  .view_pd {
    grid-row: 2 / 3;
    min-height: 0;
    max-height: 100%;
  }

}