@import '../../common/styles/variables';
@import '../../common/styles/mixins';

.component_view {
  display: grid;
  grid-template-rows: 3rem 2.5rem 1fr;
  grid-template-columns: 1fr;

  min-height: 0;
  max-height: 100%;

  align-content: stretch;
  justify-content: stretch;
  align-items: stretch;

  .head {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    border-bottom: 3px solid $color-light-border;
    display: flex;
    align-items: center;
  }

  .content {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    overflow: auto;
  }

}

.component_category_container {

  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;

  grid-column: 1 / 2;
  grid-row: 2 / 3;

  .separator {
    content: '';
    display: block;
    width: 1px;
    height: 1.75rem;
    background-color: $color-light-border;
    align-self: center;
    margin-bottom: 4px;
  }

  .category {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-size-large;
    border-bottom: 3px solid transparent;

    &:global(.active) {
      border-bottom-color: $color-upc-pastel;
    }

    &:hover {
      border-bottom-color: $color-light-border;
    }
  }

}

.component_users_btn {
  margin: 1rem;
  >*:not(:last-child) {
    margin-right: .5rem;
  }
}

.component_users_data {
  //padding: 0 1rem;
}