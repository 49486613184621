.panel_content_head {
  padding: 0 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  .head__title {
    h2 {
      display: inline-block;
      font-weight: 500;
      font-size: $font-size-big;
    }

    span {
      display: inline-block;
      font-size: $font-size-large;
      color: $color-gray;
      font-weight: 400;
      margin-left: 1rem;
    }
  }

  .head__btn {
    @include action-btn-outline;
  }
}


.panel_shadow {
  @include red-shadow;
}

.main-panel {
  display: grid;
  height: 100%;
  width: 100%;
  min-height: 0;
  max-height: 100%;

  grid-template-columns: 1fr 3fr;
  justify-content: stretch;
  align-items: stretch;

  > div:first-child {
    grid-area: 1 / 2 / 2 / 3;
  }

  > div:last-child {
    grid-area: 1 / 1 / 2 / 2;
  }

  .pan_bold_border {
    border-bottom-width: 3px;
  }

  .pam__btn {
    @include btn($color-upc, $color-full-white, round);
    text-align: center;
  }

}