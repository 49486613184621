body {
 max-height: 100vh;
 overflow: hidden;
}
//
//@for $i from 1 through 5 {
//  .w-#{$i * 25} {
//    width: #{$i * 25%};
//  }
//}

#root {
  overflow: hidden;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-container {
  display: flex;
  flex-direction: column;
  padding-top: $header-height;
  min-height: 0;
  max-height: 100vh;
  overflow-y: hidden;
  flex-grow: 1;


  > * {
    flex-grow: 1;
  }
}

@each $name, $position in $flex-position {
  .flex-#{$name} {
    @include flex-position($name);
  }
}

