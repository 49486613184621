@import '../variables';
@import '../mixins';

.no_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    .svg {
      width: 8rem;
      height: 8rem;
      color: $color-light-border;
    }

    .text {
      font-size: $font-size-big;
    }

    .new_btn {
      @include btn($color-upc, $color-full-white, small);
      text-align: center;
    }
  }
}