@import '../../common/styles/variables';
@import '../../common/styles/mixins';

.ac_year_el {

  padding: 1rem;
  margin: .25rem 0;
  border-left: 3px solid $color-upc;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: .5rem;
  @include inline-id;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  margin-left: auto;

  .action {
    color: $color-upc;
    @include action-btn-outline;
  }
}

.archived {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  padding: 0 0.5rem;
  height: 1.5rem;
  border: 2px solid #c1752c;
  border-radius: 1rem;
  color: #c1752c;
  svg {
    height: 1rem;
    width: 1rem;
  }
}