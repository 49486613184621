@import '../../feature/common/styles/variables';
@import '../../feature/common/styles/mixins';

.body_fields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.body_dual_fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  justify-content: stretch;
}

.modal_relative_container {
  position: fixed;
  z-index: 1111;
  pointer-events: auto;
}

.layout_container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: none !important;
  pointer-events: none;
  z-index: 1002;
}

.modal_fix_container {
  pointer-events: auto;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  background-color: rgba(50, 50, 50, 0.8);
  //background-color: transparent;
  position: fixed;
  z-index: 1111;
  overflow: auto;
}

.modal {
  display: inline-block;
  background-color: $color-full-white;
  border: 1px solid #888;
  min-width: 20%;
  max-width: 60%;
  border-radius: .5rem;
  //@include media-breakpoint-down(md) {
  //  min-width: 80%;
  //  max-width: 90%;
  //}

  max-height: 90vh;
  overflow-y: auto;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    padding: 1.5rem;

    .title {
      font-size: $font-size-big;
      font-weight: 500;
    }
  }

  .content {
    //min-height: 8rem;
    padding: 0 1.5rem 1.5rem 1.5rem;
  }

  .footer {
    padding: .75rem 1.5rem;
    background-color: $color-light-white;

  }

}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  flex-direction: row;
  gap: .5rem;

  .cancel_btn {
    background: none;

    @include focus-outline;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }

  .optional_btn {
    @include btn($color-upc, $color-full-white, tiny);
  }

}

.close_button {

  font-size: 1rem;
  font-weight: bold;

  box-sizing: content-box;
  height: .5rem;
  width: .5rem;
  padding: 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;

  &:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75;
  }

  @include focus-outline;

  &.disabled, &:disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25;
  }

}

.label {
  font-size: $font-size-body;
  font-weight: 500;
  text-transform: uppercase;
}

.field_checkbox {
  .input_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .label_container {
    display: flex;
    gap: .5rem;
  }

  .svg {
    height: $font-size-large;
    width: $font-size-large;
  }
  .description {
    margin-top: .25rem;
    word-wrap: break-word;
    max-width: 25rem;
    font-size: $font-size-small;
  }
}

.field {

  .input_container {
    margin-top: .5rem;
    height: 2rem;
    border-radius: .5rem;
    background-color: $color-light-border;

    display: flex;
    align-items: center;

    padding: .25rem .5rem;

    gap: .25rem;

    overflow: hidden;

    &[data-type='select'] {
      padding: 0 .5rem;
    }

    &[data-type='color'] {
      padding: 0;
      position: relative;
      .svg {
        position: absolute;
        top: .25rem;
        right: .5rem;
      }
    }

  }
  .svg {
    height: 1.5rem;
    width: 1.5rem;
  }

  .input, .select, :global(.color-picker) {
    flex-grow: 1;
    background: none;
    border: none;
    outline: none;
    height: 100%;
    cursor: pointer;
  }

  .select {
    @include selectCustomArrow;
    background-position-x: 100%;
  }
}

.error {
  font-size: $font-size-small;
  color: $color-error;
  margin-top: .25rem;
}

.popout_small {
  width: 30rem;
  max-height: min(85vh, 40rem);
  min-height: 10rem;
}

.popout_medium {
  width: 30rem;
  max-height: min(60vh, 60rem);
  min-height: 30rem;
}

.popout_large {
  width: 30rem;
  max-height: 85vh;
  min-height: 45vh;
}

.modal_switch_category {

  width: 100%;
  display: flex;
  flex-direction: row;

  margin-bottom: .5rem;

  .switch_category_btn {
    font-size: $font-size-body;
    font-weight: 500;
    text-transform: uppercase;
    flex-grow: 1;
    padding: .25rem 0;
    background: none;
    outline: none;
    border-bottom: 2px solid transparent;

    &.active, &:hover, &:focus {
      border-bottom-color: $color-upc-pastel;
    }
  }
}

.checkbox_wrapper {
  &:focus:not(.focus-visible) {
    outline: 0;
  }
  input[type=checkbox] {
    cursor: default;
    appearance: none;
    background-color: #dfe1e4;
    border-radius: 72px;
    border-style: none;
    flex-shrink: 0;
    height: 20px;
    margin: 0;
    position: relative;
    width: 30px;

    &, &:after {
      transition: all 100ms ease-out;
    }

    &:before {
      bottom: -6px;
      content: "";
      left: -6px;
      position: absolute;
      right: -6px;
      top: -6px;
    }

    &::after {
      background-color: #fff;
      border-radius: 50%;
      content: "";
      height: 14px;
      left: 3px;
      position: absolute;
      top: 3px;
      width: 14px;
    }
    &:hover {
      background-color: #c9cbcd;
      transition-duration: 0s;
    }
    &:checked {
      background-color: $color-upc-pastel;
      &:hover {
        background-color: $color-upc;
      }
      &::after {
        background-color: #fff;
        left: 13px;
      }
    }

  }
}