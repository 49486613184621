@import "../../../group/styles/Group.module";


.group {
    border-radius: 0;
}

.action_container {
    @include flex-position(center);
    flex-direction: row;
    gap: .75rem;
}

.group__disable {
    .group {
        background-color: $color-light-gray;
    }
    .group__color {
        background-color: $color-gray !important;
    }
}