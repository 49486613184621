@import '../../../common/styles/variables';

.table {
  border-collapse: collapse;
  width: 100%;
  th, td {
    padding: .5rem 1rem;
  }
}

.head {
  position: sticky;
  top: 0;
  tr {
    background-color: $color-upc-pastel;
    color: #ffffff;
    text-align: left;
  }
}
.body {
  tr {
    //scroll-snap-align: start;
    //scroll-snap-stop: normal;
    border-bottom: 1px solid #dddddd;
    &:nth-last-of-type(even) {
      background-color: #f3f3f3;
    }
    &:nth-last-of-type(odd) {
      background-color: #ffffff;
    }
    &:last-of-type {
      //border-bottom: 2px solid $color-upc-pastel;
    }
  }
}