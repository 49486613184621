@import '../../common/styles/variables';
@import '../../common/styles/mixins';

.user_perm_line {
  padding: 1rem;
  margin: .25rem 0;
  border-left: 3px solid $color-upc;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: .5rem;
  @include inline-id;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  justify-self: center;
  //margin-left: auto;

  .action {
    button {
      color: $color-upc;
    }

    //@include action-btn-outline;
  }
}

.permissions_names {
  white-space: nowrap;
  max-width: 100%;
  flex-grow: 1;
  font-size: $font-size-small;
  color: $color-dark-gray;
  text-overflow: ellipsis;
  overflow: hidden;
}