@import '../variables';
@import '../mixins';

.Header {
  background-color: $header-background-color;
  height: $header-height;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;

  display: flex;
  justify-content: stretch;
  flex-direction: row;
  align-items: center;

  padding: 0 16px;
  gap: 2rem;

  .header__start {
    display: flex;
    flex-direction: row;
    align-items: center;
    //align-self: flex-start;
  }

  .header__end {
    //align-self: flex-end;
  }
}

.header__brand {

  display: flex;
  flex-direction: row;
  align-items: center;

  .brand__logo {
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
  }

  .brand__logo_text {
    //padding: .25rem 0;
    display: inline-block;
    margin-left: .5rem;
    color: $color-full-black;

    > *:first-child {
      font-weight: bold;
      font-size: $font-size-large;
    }

    > *:last-child {
      color: $color-light-black;
      font-weight: 400;
      font-size: $font-size-body;
    }
  }
}

.header__user {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5rem;
  border-radius: .25rem;
  padding: .25rem;
  .user__logo {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: $color-full-black;
  }
  .user__arrow {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    color: $color-light-black;
  }
  &:hover {
    cursor: pointer;
    background: #bebebe;
  }
}

.header__nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  gap: 2rem;
  height: 100%;

  .nav__item {
    .nav__item__icon {
        width: 1.5rem;
        height: 1.5rem;
        color: $color-light-black;
    }
    .icon__active {
      display: none;
    }

    &.active, &:hover, &:focus {
      .nav__item__icon:not(.icon__active) {
        display: none;
      }
      .icon__active {
        display: unset;
      }
    }
  }
}
