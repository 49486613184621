@import '../../../common/styles/variables';
@import '../../../common/styles/mixins';

.settings_container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows:  1fr 1fr;

  justify-content: stretch;
  align-content: stretch;

  height: 100%;

  .students {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    //display: flex;
    //flex-direction: column;
    //align-items: center;
    //justify-content: center;
    .student {
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }
  }

  .students_btn {
    display: flex;
    flex-direction: row;
  justify-content: center;
    gap: .5rem;
  }

  .columns {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  .groups {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .view_left {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    @include red-shadow;
    z-index: 20;
    pointer-events: none;
  }

}
