@import '../../common/styles/variables';
@import '../../common/styles/mixins';

.group__link {
  &:global(.active) .group, &:hover .group {
    background-color: $color-dark-white;
  }
}

.group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  color: $color-full-black;

  background-color: $color-light-white;
  border-radius: .5rem;
  height: 2rem;
  padding: .5rem 1rem;
  &:hover .group__delete svg {
    display: unset;
  }
}

.group__color {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
}

.group__delete {
  margin-right: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  height: 100%;
  svg {
    margin-bottom: 1px;
    display: none;
    height: 1rem;
    width: 1rem;
  }
}

.group__name {
  flex-grow: 1;
  text-align: left;
}

.group__students_count {
  width: 4rem;
  display: flex;
  align-items: center;

  .students_count__ico {
    display: inline-block;
    height: 1rem;
    width: 1rem;
  }

  .students_count__number {
    margin-right: .25rem;
    display: inline-block;
  }
}

.groups_list {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding: 1rem;
  @include scrollable
}