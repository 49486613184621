@import '../../../common/styles/variables';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.box {
  display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    gap: 2rem;
}

.file {

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;



    + label {

      font-size: $font-size-large;
      /* 20px */
      font-weight: 700;
      white-space: nowrap;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      padding: 0.625rem 1.25rem;
      color: $color-upc-pastel;

      transition: color, background-color 0.2s ease-in-out;

      figure {
        width: 6.5rem;
        height: 6.5rem;
        border-radius: 50%;
        background-color: $color-upc-pastel;
        display: block;
        padding: 20px;
        margin: 0 auto 10px;
        transition: color, background-color 0.2s ease-in-out;
      }

      svg {
        width: 100%;
        height: 100%;
        fill: $color-light-white;
        color: $color-light-white;
        vertical-align: middle;
      }

    }
    &:focus + label, + label:hover {
      color: $color-upc;
      figure {
        background-color: $color-upc;
      }
    }

  }

  span {

  }
}

.select_acyear {

  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  select {
    padding: 0.5rem 2rem;
    border-radius: 1rem;
  }

  label {
    font-size: $font-size-large;
    /* 20px */
    font-weight: 700;
    white-space: nowrap;
    padding: 0.625rem 1.25rem;
    color: $color-upc-pastel;

    transition: color, background-color 0.2s ease-in-out;
  }


}