.ac_year_name {
  word-wrap: break-word;
  font-style: italic;
  background-color: #eeeeee;
  padding: .25rem .5rem;
}

.danger_info {
  background: #660e00;
  color: #fff;
  margin: 0.5rem;
  padding: 1rem;
  p:not(:first-child) {
    margin-top: .25rem;
  }
}