@import '../../../common/styles/variables';
@import '../../../common/styles/mixins';

.view {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 48px 1fr;
  height: 100%;
  flex-grow: 1;

  max-height: 100%;
  min-height: 0;

  .top {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    border-bottom: 1px solid $color-light-border;
  }

  .left {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    border-right: 1px solid $color-light-border;
    overflow-y: auto;
  }

  .right {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    padding: 24px;
    overflow-y: auto;
    z-index: 10;
    @include red-shadow;
  }
}

.title_container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;
  gap: 1rem;
}

.title {
  font-size: 20px;
  font-weight: 500;
}

.view_data {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  max-height: 100%;
  min-height: 0;

  .nav {
    width: 100%;
    height: 3rem;
    border-bottom: 1px solid $color-light-border;
    display: flex;
    flex-direction: row;

    .nav__separator {
      display: block;
      width: 1px;
      height: 100%;
      background-color: $color-light-border;
    }

    .nav_item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      height: 100%;
      cursor: pointer;
      background-color: unset;
      padding: .5rem 1rem;
      border-top: 2px solid transparent;

      &:hover {
        border-top-color: $color-light-border;
      }

      &.selected {
        border-top-color: $color-upc-pastel;
      }
    }

  }

  .body {
    overflow-y: auto;
    flex-grow: 1;
    padding: 1rem;
  }
}