@import '../../common/styles/variables';
@import '../../common/styles/mixins';

.select_box {
  display: flex;
  flex-direction: column;
  background-color: $color-full-white;
  border-radius: .5rem;
  @include red-shadow;

  .box__head {
    overflow-x: hidden;
    flex: 0 0 auto;
    padding: 1rem;
    .search_bar {
      height: 2rem;
    }
  }

  .box__body {
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    border-bottom: 1px solid $color-light-border;
    border-top: 1px solid $color-light-border;
  }

  .box__window {
    height: unset !important;
  }

  .box__foot {
    overflow-x: hidden;
    flex: 0 0 auto;
    padding: 1rem;
    .validate {
      width: 100%;
      text-align: center;
      font-size: $font-size-body;
      padding: .5rem 0;
      background-color: $color-upc-pastel;
      color: white;
      transition: background-color .2s ease-in-out;
      &:hover {
        background-color: $color-upc;
      }
    }
  }
}

a.student {
  border-left: 3px solid transparent;
  &:hover {
    border-left-color: $color-light-border;
  }

  &:global(.active) {
    border-left-color: $color-upc-pastel;
  }
}

.student {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 2rem;
  padding: .25rem 1rem;

  .student__id {
    display: inline;
  }

  .student__id_ico {
    height: 1rem;
    width: 1rem;
    position: relative;
    top: 0.125rem;
    color: $color-upc-pastel;
  }

  .students__info {
    > *:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &:hover,&:global(.active) {
    background-color: $color-light-white;
  }



  .student__checkbox {
    align-self: flex-end;
  }
}

.selected_students {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
  max-height: 6rem;
  overflow: hidden auto;
  margin: .5rem 0;
  //&:empty {
  //  display: none;
  //}
}

.select_student {
  background-color: $color-light-white;
  border-radius: 1rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  padding: 0 .5rem;
  .students__info {
    font-size: $font-size-small;
    display: inline-block;
    > span:not(:last-child) {
      margin-right: .5rem;
    }
  }
  .remove {
    display: inline-block;
    margin-left: .5rem;
    background: none;
    cursor: pointer;
    border-radius: 50%;
    height: 1rem;
    width: 1rem;
    padding-top: 1px;
    &:hover {
      background-color: $color-upc;
      color: white;
    }
    svg {
      height: .75rem;
      //width: 1rem;
    }
  }
}