@import '../../common/styles/variables';
@import '../../common/styles/mixins';

.main {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.container {
  position: relative;
  width: 50%;
  height: 60%;
  box-shadow: 0 4px 30px rgba(0, 0, 0, .3);
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.select_type {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  .type_upc {
    @include btn(#8A1538, $color-full-white, large);
    width: 24rem;
  }

  .type_local {
    @include btn($color-upc, $color-full-white, large);
    width: 24rem;
  }

  .type_or {
    font-size: $font-size-title;
    text-transform: uppercase;
  }
}

.login,
.ac_year {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 1rem;

  button:not(.notme),
  .btn {
    @include btn($color-upc, $color-full-white, medium);
    text-align: center;
  }

}

.login {

  .login__error {
    background-color: rgba($color-error, .75);
    padding: .5rem;
    border-radius: .5rem;
    max-width: 85%;
    color: $color-light-white;
    text-align: center;
  }

  .login_profile {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: $color-upc;
    padding: .25rem;

    svg {
      width: 3.5rem;
      height: 3.5rem;
      color: white;
    }
  }

  input {
    width: 16rem;
    height: 2rem;
    padding: 0 2rem;
    border-radius: 1rem;
    border: none;
    background-color: $color-light-border;
    @include focus-outline;
  }
}

.ac_year {
  .ac_year__user {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
  }

  .ac_year_profile {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
  }

  select {
    width: 16rem;
    height: 2rem;
    padding: 0 2rem;
    border-radius: 1rem;
    border: none;
    background-color: $color-light-border;
    @include selectCustomArrow;
  }
}

.main:not(.login_active) {
  .background {
    left: 50%;
  }
}

.main.no_transition {
  .background {
    transition: none;
  }
}


.no_access_error {
  font-weight: 500;
  color: $color-full-white;
  border-radius: .5rem;
  background-color: $color-error;
  padding: 1rem;
}

.notme {
  background: none;
  color: $color-upc;

  &:hover {
    background: none;
    color: $color-upc;
    text-decoration: underline;
  }
}

.cas_register_profile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;


  .cas_register_id {
    >* {
      display: block;
    }
  }
}
