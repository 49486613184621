@import '../variables';
@import '../mixins';

.global {
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
}

.style_round_upc {
    @include _btn__color($color-upc, $color-full-white)
}

.style_round_gray {
  @include _btn__color($color-light-border, $color-dark-gray)
}

.style_clear_large {
  background: none;
  height: 1.5rem;
  width: 1.5rem;
}

.style_clear_small {
  background: none;
  height: 1rem;
  width: 1rem;
}

.size_large {
  @include _btn__size(round)
}

.size_medium {
  @include _btn__size(round-small)
}

.button_trigger_on_hover {
  @include _btn__transition(switch_second_icond, none, none)
}

.transition_bg_white {
  &.style_round_upc {
    @include _btn__transition(background_inverted, $color-upc, $color-full-white)
  }
  &.style_round_gray {
    @include _btn__transition(background_inverted, $color-dark-gray, $color-full-white)
  }
}