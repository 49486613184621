@import './variables';
@import './mixins';

.select_box {
  display: inline-block;
  height: 1.75rem;
  border: 1px solid $color-upc;
  border-radius: .25rem;

  select {
    flex-basis: 1rem;
    background: none;
    border-radius: 0.25rem;
    padding: 0 1rem;
    outline: none;
    height: 100%;
    cursor: pointer;
    @include selectCustomArrow;
    background-position-x: 100%;
    background-position-y: 60%;
    appearance: none;
  }

}

.group {
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    border-radius: 0;

    &:first-child {
      border-radius: .25rem 0 0 .25rem;
    }

    &:last-child {
      border-radius: 0 .25rem .25rem 0;
    }
  }
}


.check_box {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  border: 1px solid $color-upc;
  border-radius: .25rem;

  & :checked~.icon_box {
    background-color: $color-upc-pastel;
  }

  .icon_box {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: $color-upc;
    }
  }


  svg {
    width: 1rem;
  }

  input {
    display: block;
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
    appearance: none;
  }
}
