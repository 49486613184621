@import '../../common/styles/variables';
@import '../../common/styles/mixins';

.calendar {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.calendar__body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  padding: 0 1rem 1rem 1rem;
}

.calendar__body::-webkit-scrollbar {
  width: 0;
}

.calendar__body_empty {
  justify-content: center;
  flex-grow: 1;
}

.calendar__day_separator {
  width: 100%;
  height: 16px;
  background-color: $color-light-white;
  font-size: $font-size-small;
}

.event {
  container: event / inline-size;

  .event__head {
    width: 100%;
    height: .5rem;
  }


  .event__body {
    background-color: $color-light-white;
    padding: .25rem .5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;

    font-size: $font-size-small;
    color: $color-full-black;
    gap: .25rem;

    .child_title {
      grid-column: 1 / 3;

      font-size: $font-size-small;
      font-weight: bold;
    }

    .child_room {
      grid-column: 3 / 4;
      justify-self: end;
    }

    .child_time {
      grid-column: 1 / 4;
      color: $color-gray;
    }

    .child_tags {
      grid-column: 1 / 4;
      color: $color-gray;
      display: flex;
      flex-wrap: wrap;
    }
  }

  .event__link {
    width: 100%;
    height: 100%;

    &:global(.active) .event__body,
    &:hover .event__body {
      background-color: $color-dark-white;
    }
  }
}

@container event (inline-size < 256px) {
  .event__body {
    grid-template-rows: 1fr 1fr 1fr 1fr !important;

    .child_title {
      grid-column: 1 / 3 !important;
    }

    .child_room {
      grid-column: 1 / 3 !important;
      justify-self: start !important;
    }
  }
}

.students__table {
  @include tableXPadding(2rem);

  tbody tr:hover {
    background-color: $color-light-border;
  }

  .students__table__red_warn {
    background-color: $color-error-bg;
  }
}

.students__container {
  display: flex;
  flex-direction: column;
  min-height: 0;
  max-height: 100%;
  height: 100%;
}

.students__head {
  display: flex;
  border-bottom: 3px solid $color-light-border;
  height: 2rem;
  padding: 0 2rem;
  align-items: center;
  justify-content: space-between;

  .students__head_search {
    flex-basis: 30rem;
  }

  .students_counts {
    display: inline-block;
    margin-right: 1rem;
    font-size: $font-size-body;

    .sc_present {
      color: #41D383;
    }

    .sc_abs {
      color: #FF7575;
    }

    .sc_total {
      color: #110822;
    }

    >*:not(:last-child):after {
      content: '|';
      margin: 0 .5rem;
    }
  }

  .students__head_left {
    display: flex;
    gap: .5rem;
  }

  .students__head_title {
    display: flex;
    flex-direction: row;
    align-items: center;

    >svg {
      height: $font-size-large;
      width: $font-size-large;
    }

    >span {
      font-size: $font-size-large;
      color: $color-full-black;
      font-weight: 400;
      margin-left: 1rem;
    }
  }

}

.calendar_settings {
  height: 1.5rem;
  padding: 0 1rem;
  margin: .25rem 0;

  select {
    width: 6rem;
  }
}

.calender__week {
  height: 2rem;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .week__before,
  .week__after {
    background: none;
    cursor: pointer;
    color: black;

    svg {
      height: 2rem;
      width: 2rem;
    }

    &:hover svg {
      color: $color-upc;
    }
  }

  .week__name {
    font-size: $font-size-body;
  }
}

.row_scan_present {
  background-color: #97c188;
}

.row_scan_absent {
  background-color: #b34747;
}

.icon_present {
  color: #97c188 !important;
}

.icon_absent {
  color: #b34747 !important;
}

.icon_justified {
  color: #f58442 !important;
}

.scan_counts {
  font-variant-numeric: proportional-nums;

  >* {
    padding: 0 0.5rem;
  }
}
