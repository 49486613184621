@import '../../common/styles/variables';
@import '../../common/styles/mixins';

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  min-height: 0;
  max-height: 100%;

  align-content: stretch;
  justify-content: stretch;
  align-items: stretch;
}

.result_container {
  @include flex-position(center)
}

.result {

}

.students_container {
  min-height: 0;
  max-height: 100%;
}