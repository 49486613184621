@function assets-font-prefix($base) {
  @return '/res/font/'+$base;
}

$btn_size: (
  tiny: (padding: .125rem .75rem,
    font-size: .75rem),
  small: (padding: .25rem 1rem,
    font-size: 1rem),
  medium: (padding: .5rem 2rem,
    font-size: 1.25rem),
  large: (padding: .75rem 3rem,
    font-size: 1.5rem,
  ),

  round: (padding: calc(.5rem - 2px),
    font-size: 1rem),

  round-small: (padding: .125rem,
    font-size: .85rem)
);


$btn_transition: (
  none,
  background_inverted,
  switch_second_icond,
);

@mixin _btn__color($cb, $ct) {
  @if ($cb !=none) {
    border: 2px solid $cb;
    background-color: $cb;
  }

  color: $ct;
}

@mixin _btn__transition($transition: background_inverted, $ct, $cb) {
  transition: all .5s ease-out;

  @if ($transition==background_inverted) {
    &:hover {
      background-color: $cb;
      color: $ct;
    }
  }

  @else if($transition==switch_second_icond) {
    @include action-btn-outline(false);
  }
}

@mixin _btn__size($sizen: medium) {
  $size: map-get($btn_size, $sizen);

  @if (($sizen ==round) or ($sizen ==round-small)) {
    box-sizing: content-box;
    border-radius: 50%;
    width: map-get($size, font-size);
    height: map-get($size, font-size);
  }

  @else {
    border-radius: 5rem;
  }

  padding: map-get($size, padding);
  font-size: map-get($size, font-size);
  font-weight: 400;

  svg {
    height: map-get($size, font-size);
    width: map-get($size, font-size);
  }
}

@mixin btn($cb, $ct, $sizen: medium, $transition: background_inverted) {

  text-decoration: none;
  cursor: pointer;

  @include _btn__color($cb, $ct);
  @include _btn__size($sizen);
  @include _btn__transition($transition, $cb, $ct);

  &:disabled {
    opacity: .8;
    background-color: #989292;
    border-color: #989292;
    cursor: not-allowed;

    &:hover {
      color: $ct;
    }
  }
}

@mixin action-btn-outline($def-style: true) {
  @if ($def-style==true) {
    height: 1.5rem;
    width: 1.5rem;
    background: none;
  }

  svg:last-child {
    display: none;
  }

  &:hover,
  &:focus {
    svg:first-child {
      display: none;
    }

    svg:last-child {
      display: unset;
    }
  }
}

@mixin red-shadow() {
  box-shadow: 0 5px 15px var(--color-upc-shadow);
}

@mixin scrollable {
  overflow-y: auto;
  overflow-x: hidden;
}

@mixin hideScrollBar {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin recolor($color: #000, $opacity: 1) {
  //$r: red($color) / 255;
  //$g: green($color) / 255;
  //$b: blue($color) / 255;
  //$a: $opacity;

  $lightness: lightness($color);
  filter: saturate(0%) brightness(0%) invert($lightness) opacity($opacity);
}

@mixin tableXPadding($padding: 2rem) {

  tr td,
  tr th {
    &:first-child {
      padding-left: $padding;
    }

    &:last-child {
      padding-right: $padding;
    }
  }
}

@mixin selectCustomArrow {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;

  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 5px;
}

@mixin focus-outline() {
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem var(--color-upc-shadow);
  }
}

$flex-position: (
  top-left: (justify-content: flex-start,
    align-items: flex-start),
  top-right: (justify-content: flex-start,
    align-items: flex-end),
  bottom-left: (justify-content: flex-end,
    align-items: flex-start),
  bottom-right: (justify-content: flex-end,
    align-items: flex-end),
  center: (justify-content: center,
    align-items: center),
  center-left: (justify-content: center,
    align-items: flex-start),
  center-right: (justify-content: center,
    align-items: flex-end),
  center-top: (justify-content: flex-start,
    align-items: center),
  center-bottom: (justify-content: flex-end,
    align-items: center)
);

@mixin flex-position($pos: center) {
  $position: map-get($flex-position, $pos);
  display: flex;
  flex-direction: column;
  justify-content: map-get($position, justify-content);
  align-items: map-get($position, align-items);
}



@mixin inline-id {

  .id {
    justify-self: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: .25rem;
    width: 4rem;
    height: 1.5rem;
    border-radius: 1.5rem;
    background-color: $color-dark-white;
    margin-right: 1rem;
  }

  .pound {
    width: 1rem;
    height: 1rem;
    color: $color-upc-pastel;
  }
}
