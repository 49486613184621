:root {
  //--color-upc: #8A1538;
  //--color-upc-shadow: rgba(138, 21, 56, 0.3);
  //--color-upc-pastel: #CD5D7D;
  --color-upc: #207444;
  --color-upc-shadow: rgba(19, 89, 49, 0.3);
  --color-upc-pastel: #70AF85;
}

.modal-create-event, .modal-create-group {
  width: max(25%, 512px);
}

.tooltip-container {
  z-index: 2000;
}

body {
  background-color: $color-full-white;
}

.intext-upc {
    color: $color-upc;
}

.inline {
    display: inline;
}

.dropdown-arrow {

}