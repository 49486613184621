@import '../../common/styles/variables';
@import '../../common/styles/mixins';

.event_view {
  display: grid;
  grid-template-rows: 3rem 1fr 1fr;
  grid-template-columns: 5fr 2fr;
  min-height: 0;
  max-height: 100%;

  align-content: stretch;
  justify-content: stretch;
  align-items: stretch;

  .view_pt {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    border-bottom: 3px solid $color-light-border;
  }

  .view_center {
    grid-column: 1 / 2;
    grid-row: 2 / 4;
  }

  .view_left {
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    @include red-shadow;
    z-index: 20;
    pointer-events: none;
  }

  .view_tl {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .view_bl {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
  }
}

.students_container {
  min-height: 0;
  max-height: 100%;
}

.groups__table {
  @include tableXPadding(1rem);
  tbody tr{
    background-color: $color-dark-white;
    &:nth-child(odd) {
      background-color: $color-light-white;
    }
    &:hover {
      background-color: $color-light-border;
    }
  }
}

.groups_add_container {
  padding: 1rem 0;
  text-align: center;
  .groups_add_btn {
    @include btn($color-upc, $color-full-white, small);
    text-align: center;
  }
}

