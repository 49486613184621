@import '../variables';
@import '../mixins';

.popup {
  padding: 0 1rem 1rem 1rem;
  background-color: $header-background-color;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

}
.acYear {

}
.logout {

}

.logout_btn {
  color: $color-upc-pastel;
  background: none;
  display: flex;
    align-items: center;
  cursor: pointer;
  .logout__icon {
      height: 1rem;
     width: 1rem;
  }
  span {
    margin-left: .5rem;
  }
}

.separator {
  height: 2px;
  background-color: $color-light-border;
  margin: .5rem 0;
  width: 100%;
  &:first-child {
    margin-top: 0;
  }
}

.current_user {
  span {
    display: block;
  }
}