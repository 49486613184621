@import './variables';
@import './mixins';

.tag {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  /* background-color: var(--color); */
  border: 2px solid var(--color);
  padding: 0.25rem 0.5rem;
  color: var(--color);
  height: 1.25rem;
  border-radius: .75rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  svg {
    height: 1rem;
    width: 1rem;
    color: var(--color);
    margin-right: .5rem;
  }

  span {
    font-weight: bold;
  }

  &.clickable {
    &:hover {
      cursor: pointer;
      background-color: $color-full-white;
      color: $color-full-black;
    }
  }

}
