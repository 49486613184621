@import '../../../common/styles/variables';
@import '../../../common/styles/mixins';


.scrollable {
  @include scrollable;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  //
  //column-count: auto;
  //column-width: 32rem;

}

.event_container {
  min-width: 32rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5rem;
  padding: 0.5rem 1rem;
  flex: 1 1 33%;

  .icon {
    height: 3rem;
    width: 3rem;

    &.ok {
      color: $color-success;
    }

    &.justified {
      color: $color-warning;
    }

    &.wrong {
      color: $color-error;
    }

    &.neutre {
      color: $color-gray;
    }
  }

  .event {
    flex-grow: 1;
  }
}
