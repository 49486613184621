/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2018 Schwartzco Inc.
        License: 1812-XWBAJH
*/

body {
  font-feature-settings: "liga", "kern";
  font-family: 'Darby Sans Web', 'Lucida Grande', sans-serif;
  font-stretch: normal;
}

@font-face {
  font-family: 'Darby Sans Web';
  src: url(assets-font-prefix('DarbySans-BlackItalic-Web.woff2')) format('woff2'),
  url(assets-font-prefix('DarbySans-BlackItalic-Web.woff')) format('woff');
  font-weight:  900;
  font-style:   italic;
  font-stretch: normal;
}

.DarbySans-BlackItalic-Web {
  font-family: 'Darby Sans Web';
  font-weight:  900;
  font-style:   italic;
  font-stretch: normal;
}


@font-face {
  font-family: 'Darby Sans Web';
  src: url(assets-font-prefix('DarbySans-Black-Web.woff2')) format('woff2'),
  url(assets-font-prefix('DarbySans-Black-Web.woff')) format('woff');
  font-weight:  900;
  font-style:   normal;
  font-stretch: normal;
}

.DarbySans-Black-Web {
  font-family: 'Darby Sans Web';
  font-weight:  900;
  font-style:   normal;
  font-stretch: normal;
}


@font-face {
  font-family: 'Darby Sans Web';
  src: url(assets-font-prefix('DarbySans-BoldItalic-Web.woff2')) format('woff2'),
  url(assets-font-prefix('DarbySans-BoldItalic-Web.woff')) format('woff');
  font-weight:  700;
  font-style:   italic;
  font-stretch: normal;
}

.DarbySans-BoldItalic-Web {
  font-family: 'Darby Sans Web';
  font-weight:  700;
  font-style:   italic;
  font-stretch: normal;
}


@font-face {
  font-family: 'Darby Sans Web';
  src: url(assets-font-prefix('DarbySans-Bold-Web.woff2')) format('woff2'),
  url(assets-font-prefix('DarbySans-Bold-Web.woff')) format('woff');
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
}

.DarbySans-Bold-Web {
  font-family: 'Darby Sans Web';
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
}


@font-face {
  font-family: 'Darby Sans Web';
  src: url(assets-font-prefix('DarbySans-MediumItalic-Web.woff2')) format('woff2'),
  url(assets-font-prefix('DarbySans-MediumItalic-Web.woff')) format('woff');
  font-weight:  500;
  font-style:   italic;
  font-stretch: normal;
}

.DarbySans-MediumItalic-Web {
  font-family: 'Darby Sans Web';
  font-weight:  500;
  font-style:   italic;
  font-stretch: normal;
}


@font-face {
  font-family: 'Darby Sans Web';
  src: url(assets-font-prefix('DarbySans-Medium-Web.woff2')) format('woff2'),
  url(assets-font-prefix('DarbySans-Medium-Web.woff')) format('woff');
  font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
}

.DarbySans-Medium-Web {
  font-family: 'Darby Sans Web';
  font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
}


@font-face {
  font-family: 'Darby Sans Web';
  src: url(assets-font-prefix('DarbySans-RegularItalic-Web.woff2')) format('woff2'),
  url(assets-font-prefix('DarbySans-RegularItalic-Web.woff')) format('woff');
  font-weight:  400;
  font-style:   italic;
  font-stretch: normal;
}

.DarbySans-RegularItalic-Web {
  font-family: 'Darby Sans Web';
  font-weight:  400;
  font-style:   italic;
  font-stretch: normal;
}


@font-face {
  font-family: 'Darby Sans Web';
  src: url(assets-font-prefix('DarbySans-Regular-Web.woff2')) format('woff2'),
  url(assets-font-prefix('DarbySans-Regular-Web.woff')) format('woff');
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}

.DarbySans-Regular-Web {
  font-family: 'Darby Sans Web';
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}


@font-face {
  font-family: 'Darby Sans Web';
  src: url(assets-font-prefix('DarbySans-LightItalic-Web.woff2')) format('woff2'),
  url(assets-font-prefix('DarbySans-LightItalic-Web.woff')) format('woff');
  font-weight:  300;
  font-style:   italic;
  font-stretch: normal;
}

.DarbySans-LightItalic-Web {
  font-family: 'Darby Sans Web';
  font-weight:  300;
  font-style:   italic;
  font-stretch: normal;
}


@font-face {
  font-family: 'Darby Sans Web';
  src: url(assets-font-prefix('DarbySans-Light-Web.woff2')) format('woff2'),
  url(assets-font-prefix('DarbySans-Light-Web.woff')) format('woff');
  font-weight:  300;
  font-style:   normal;
  font-stretch: normal;
}

.DarbySans-Light-Web {
  font-family: 'Darby Sans Web';
  font-weight:  300;
  font-style:   normal;
  font-stretch: normal;
}


@font-face {
  font-family: 'Darby Sans Web';
  src: url(assets-font-prefix('DarbySans-XLightItalic-Web.woff2')) format('woff2'),
  url(assets-font-prefix('DarbySans-XLightItalic-Web.woff')) format('woff');
  font-weight:  200;
  font-style:   italic;
  font-stretch: normal;
}

.DarbySans-XLightItalic-Web {
  font-family: 'Darby Sans Web';
  font-weight:  200;
  font-style:   italic;
  font-stretch: normal;
}


@font-face {
  font-family: 'Darby Sans Web';
  src: url(assets-font-prefix('DarbySans-XLight-Web.woff2')) format('woff2'),
  url(assets-font-prefix('DarbySans-XLight-Web.woff')) format('woff');
  font-weight:  200;
  font-style:   normal;
  font-stretch: normal;
}

.DarbySans-XLight-Web {
  font-family: 'Darby Sans Web';
  font-weight:  200;
  font-style:   normal;
  font-stretch: normal;
}


@font-face {
  font-family: 'Darby Sans Web';
  src: url(assets-font-prefix('DarbySans-ThinItalic-Web.woff2')) format('woff2'),
  url(assets-font-prefix('DarbySans-ThinItalic-Web.woff')) format('woff');
  font-weight:  100;
  font-style:   italic;
  font-stretch: normal;
}

.DarbySans-ThinItalic-Web {
  font-family: 'Darby Sans Web';
  font-weight:  100;
  font-style:   italic;
  font-stretch: normal;
}


@font-face {
  font-family: 'Darby Sans Web';
  src: url(assets-font-prefix('DarbySans-Thin-Web.woff2')) format('woff2'),
  url(assets-font-prefix('DarbySans-Thin-Web.woff')) format('woff');
  font-weight:  100;
  font-style:   normal;
  font-stretch: normal;
}

.DarbySans-Thin-Web {
  font-family: 'Darby Sans Web';
  font-weight:  100;
  font-style:   normal;
  font-stretch: normal;
}
