@import '../../common/styles/variables';
@import '../../common/styles/mixins';

.container {
    display: flex;
    flex-direction: column;
    background-color: $color-full-white;
    border-radius: .5rem;
    justify-content: stretch;
    @include red-shadow;
}

.head {
    overflow-x: hidden;
    flex: 0 0 auto;
    padding: 1rem;

    h1 {
        font-size: $font-size-big;
        margin: 0;
    }
}

.body {
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    border-top: 1px solid $color-light-border;
}

.group_list {
    width: 100%;
}

.group_search_bar {
    height: 2rem;
    margin-top: 1rem;
}
