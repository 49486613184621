@import '../variables';
@import '../mixins';

.loader_container {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;

  &::before, &::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0;
    border-radius: 50%;
    border: 5px solid $color-light-black;
    animation: prixClipFix 2s linear infinite;
  }

  &::after {
    border-color: $color-upc;
    animation: prixClipFix 2s linear infinite, rotate 0.5s linear infinite reverse;
    inset: 6px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)
  }
}


.panel_container {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;

  display: grid;
  grid-template-rows: 3rem 1fr;
  z-index: 20;
  &.border_top {
    border-top: 1px solid $color-light-border;
  }

}

.panel__head {
  overflow: hidden;
  border-bottom: 1px solid $color-light-border;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  justify-items: end;
  padding: 0 1rem;
  gap: 1rem;

  .panel__svg {
    grid-column: 1 / 2;
    height: $font-size-title;
    width: $font-size-title;
  }

  .panel__title {
    grid-column: 2 / 3;
    justify-self: center;
    font-size: $font-size-big;
  }

  .panel__btn, a {
    grid-column: 3 / 4;
    @include btn($color-upc, $color-full-white, small);
    text-align: center;
  }
}

.panel__body {
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &.body_scrollable {
    min-height:  0;
    overflow-y: auto;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.search_bar {
  background-color: $color-light-border;
  padding: 0 .5rem;
  border-radius: .25rem;
  height: 1rem;
  display: flex;
  align-items: center;


  input {
    border: none;
    outline: none;
    background: none;
    font-size: $font-size-small;
    flex-grow: 1;
  }

  .search_bar__svg {
    height: .75rem;
    width: .75rem;
  }
}

.checkbox {
  height: 1.5rem;
  width: 1.5rem;
  border: 2px solid $color-light-border;
  border-radius: .5rem;

  .checkbox__check {
    height: 100%;
    width: 100%;

    &.checked {
      background-color: red;
      clip-path: path(evenodd, 'M 21 7 L 9 19 L 3.5 13.5 L 4.91 12.09 L 9 16.17 L 19.59 5.59 L 21 7 Z');
    }
  }

}