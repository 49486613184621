@import '../variables';
@import '../mixins';

.table_container {
  width: 100%;
  //height: 100%;
  max-height: 100%;
  min-height: 0;
  flex-grow: 1;

  @include scrollable;
  &:global(.scrolled) .table thead th {
    background-color: $color-full-white;
    //background-clip: content-box;
  }

}

.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  max-height: 100%;
  min-height: 0;


  thead {
    font-size: $font-size-large;
    position: sticky;
    top: 0;
    text-transform: uppercase;

    th {
      padding-top: .25rem;
      padding-bottom: calc(.25rem + 2px);
      font-weight: 400;
    }


    &:after {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background-color: $color-light-border;
    }
  }
}