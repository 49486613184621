@import '../../common/styles/variables';
@import '../../common/styles/mixins';

.component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 2rem;
  padding: .25rem 1rem;

  .component__id {
    display: inline;
  }

  .component__id__ico {
    height: 1rem;
    width: 1rem;
    position: relative;
    top: 0.125rem;
    color: $color-upc-pastel;
  }

  .component__info {
    > *:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &:hover,&:global(.active) {
    background-color: $color-light-white;
  }
}