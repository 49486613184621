@import '../variables';
@import '../mixins';

.table__actions {

  display: flex;
  flex-direction: row;
  align-items: center;


  .action__tooltip {
    width: fit-content;
    height: fit-content;
  }
  .action {
    display: block;
    //@include btn($color-upc, $color-full-white, round);
    height: 1.75rem;
    width: 1.75rem;
    //position: relative;
    text-align: center;
    background: none;
    color: $color-upc;
    cursor: pointer;
    svg {
      //position: absolute;
      margin: .125rem;
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}