$header-background-color: #ECECEF;
$header-height: 3rem;

$color-full-white: #ffffff;
$color-light-white: #F7F7F7;
$color-dark-white: #eeeeee;
$color-light-border: #DCDCDC;
$color-full-black: #000000;
$color-light-black: #161616;
$color-light-gray: #bebebe;
$color-gray: #808080;
$color-dark-gray: #6B6B6B;

//$color-upc: #8A1538;
//$color-upc-pastel: #CD5D7D;

$color-upc: var(--color-upc);
$color-upc-pastel: var(--color-upc-pastel);

$color-error: #b44d4d;
$color-error-bg: #e57272;
$color-warning: #d9b15d;
$color-success: #63c163;
$font-size-small: .75rem; // 12px
$font-size-body: 1rem; // text 16px
$font-size-large: 1.25rem; // h4 20px
$font-size-big: 1.5rem; // h3 24px
$font-size-title: 1.625rem; // h2 26px
$font-size-huge: 3rem; // h1 48px

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
);
